<template>
  <div>
    <div class="wrap">
      <template v-for="(item, index) in shadow">
        <div :class="{item:true, active:plateActiveIndex===index, green_car:index === 7&&!specialActive }" :key="index" @click="plateInputHandle(index)">
          <template v-if="index === 7&&!specialActive">
            +
          </template>
          <template v-else>
            {{ item }}
          </template>

        </div>
      </template>

    </div>
    <van-action-sheet safe-area-inset-bottom :lock-scroll="true" :round="false" :overlay="false" v-model="plateActive" @closed="closedHandle" @opened="sheepOpenHandle">
      <div class="keyboard-wrap" ref="plate-sheet" >
        <!--键盘顶部-->
        <van-row>
          <div class="title-wrap">
            <div class="left">
              <template v-for="(item, index) in specials">
                <van-button @click="specialChoseHandle(item)" size="small" :key="index"
                            :disabled="plateActiveIndex!==7 || !specialActive">{{ item }}
                </van-button>
              </template>
            </div>
            <div class="right">
              <van-button size="small" @click="keyboardCloseHandle">关闭</van-button>
            </div>
          </div>

        </van-row>
        <!--键盘-->
        <van-row>
          <div class="board-wrap">
            <div class="board-line" v-for="(keyBoardGroup, lineIndex) in keyBoards " :key="lineIndex">
              <template v-if="lineIndex + 1 === keyBoards.length && plateActiveIndex>0">
                <div class="board-block done" @click="boardDoneHandle" @touchstart="touchstart" @touchend="touchend">
                  完成
                </div>
              </template>
              <div class="board-line-inner">
                <div class="board-block" @click="boardClickHandle(item)" @touchstart="touchstart" @touchend="touchend"
                     v-for="(item, index) in keyBoardGroup" :key="index">
                  {{ item }}
                </div>
              </div>

              <template v-if="lineIndex + 1 === keyBoards.length">
                <div class="board-block delete" @click="boardDeleteHandle" @touchstart="touchstart" @touchend="touchend">
                  删除
                </div>
              </template>
            </div>


          </div>
        </van-row>
      </div>

    </van-action-sheet>
    <van-overlay
        :show="plateActive"
        @click="plateActive=false"
        :custom-style="{'background-color':'transparent'}"
    />

  </div>
</template>

<script>
import Vue from 'vue';
import {ActionSheet, Button, Col, Overlay, Row} from 'vant';

Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(ActionSheet);
Vue.use(Overlay);
export default {
  name: "plate",
  data() {
    return {
      plateActive: false, // 键盘开关
      specialActive: false, // 末尾特殊标记开关
      plateActiveIndex: null, //当前激活位
      shadow: new Array(8), // 车牌号结果
      provinces: [
        ['京', '津', '沪', '渝', '冀', '豫', '云', '辽', '黑', '湘'],
        ['皖', '鲁', '新', '苏', '浙', '赣', '鄂', '桂', '甘'],
        ['晋', '蒙', '陕', '吉', '闽', '贵', '粤'],
        ['青', '藏', '川', '宁', '琼', '使']
      ],
      numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      letters: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
      ],
      specials: ['领', '警', '港', '澳', '台', '学', '挂'],
    }
  },
  computed: {
    keyBoards() {
      let outBoards = []
      if (this.plateActiveIndex === 0) {
        outBoards = this.provinces
      } else {
        outBoards = outBoards.concat([this.numbers], this.letters)
      }
      return outBoards
      // return this.plateActiveIndex === 0 ? this.provinces : this.letters
    }
  },
  methods: {
    touchstart(e) {
      e.target.classList.add('board-active');
    },
    touchend(e) {
      e.target.classList.remove('board-active');
    },
    isEmpty(val) {
      return val === undefined || val === null || (typeof val === "string" && val.length === 0)
    },
    sheepOpenHandle(){
      const plateSheetTop = this.$refs["plate-sheet"].getBoundingClientRect().top
      this.$emit('sheetOpened', plateSheetTop)
    },
    // 键盘关闭
    keyboardCloseHandle() {
      this.plateActiveIndex = null
      this.plateActive = false
    },
    specialChoseHandle(specialItem) {
      console.log(specialItem)
      this.$set(this.shadow, this.plateActiveIndex, specialItem)
    },
    plateInputHandle(inputIndex) {
      let index = inputIndex
      index === 7 && (this.specialActive = true)
      const usefulShadow = this.shadow.filter(item=>{return !this.isEmpty(item)})
      if(!usefulShadow || usefulShadow.length===0){
        index = 0
      }

      this.plateActive = true
      this.plateActiveIndex = index
      // this.shadow[index] = index
      // this.$set(this.shadow, index, index)
    },

    boardClickHandle(val) {
      const flag = this.specialActive ? 7 : 6
      let index = this.plateActiveIndex

      index++
      if (index >= flag) {
        index = flag
      }

      this.$set(this.shadow, this.plateActiveIndex, val)
      this.plateActiveIndex = index
      if(this.shadow.filter(item=>!!item).length>=flag+1){
        this.keyboardCloseHandle()
        return false
      }

    },
    boardDeleteHandle(){
      this.$set(this.shadow, this.plateActiveIndex, null)
      this.plateActiveIndex > 0 && (this.plateActiveIndex--)
    },
    boardDoneHandle(){
      this.keyboardCloseHandle()
    },
    closedHandle(){
      console.log('closedHandle')
      this.$emit('closedHandle', this.shadow)
    }
  }
}
</script>

<style scoped lang="sass">
$itemColor: #F5F5F5
$itemActiveColor: #FFF1EB
$boardSpace: 6px
$greenCarBorder: #54BAAE
$greenCarBGC: #E8F7F2
.wrap
  display: flex
  width: 100%

  .item
    color: #F2733C
    width: 25px
    height: 25px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border: 1px solid $itemColor
    margin-right: 5px
    background-color: $itemColor
    border-radius: 2px

    &.active
      background-color: $itemActiveColor
      border-color: $itemActiveColor
    &.green_car
      background-color: $greenCarBGC
      border-color: $greenCarBorder
      color: $greenCarBorder

  .item:nth-last-col(8)
    margin-right: 0

.keyboard-wrap
  padding: 5px 5px
  box-sizing: border-box
  border-top: #E6E6E6 1px solid
  border-left: #E6E6E6 1px solid
  border-right: #E6E6E6 1px solid
  border-top-left-radius: 5px
  border-top-right-radius: 5px

  .title-wrap
    display: flex
    flex-direction: row
    flex: 1
    justify-content: space-between
    /*border-top: #E6E6E6 1px solid*/
    border-bottom: #E6E6E6 1px solid
    padding: 2px 0

    .left
      display: flex

      .van-button
        margin-right: 5px

    .right
      display: flex

  .board-wrap
    display: flex
    flex-direction: column
    background-color: rgba(207, 211, 221, 0.49)
    padding-top: 6px

    .board-line
      display: flex
      justify-content: space-between
      margin-bottom: 8px
      .board-line-inner
        display: flex
        flex: 1
        flex-direction: row
        justify-content: center


  .board-block
    cursor: pointer
    font-size: 14px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-color: #FFFFFF
    border-radius: 4px
    box-shadow: 0 1px 2px #666
    width: calc(10vw - #{$boardSpace})
    margin: 0 2px
    height: 40px
    &.done
      background-color: #0084FF
      color: #FFFFFF
      font-weight: 500
      padding: 0 5px
      margin-left: $boardSpace

    &.delete
      padding: 0 5px
      margin-right: $boardSpace

    &.board-active
      background-color: #000
      opacity: 0.2


</style>
