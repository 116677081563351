<template>
  <div ref="wrap" class="actifity-wrap">
    <!--分享按钮-->
    <div class="share-action-wrap" @click="shareBtnHandle">
      <div class="inner">
        <van-icon :name="require('@/assets/images/share-icon.png')" size="26"/>
        <div class="text-wrap">
          <span>生成</span>
          <span>分享</span>
        </div>
      </div>

    </div>
    <!--分享人-->
    <div class="share-user-tag" v-if="shareUser && shareUser.id">
      <div class="wrap">
        <van-image
            round
            width="25"
            height="25"
            :src="shareUser.avatarUrl"
        />
        <span>{{ shareUser.name }}分享</span>
      </div>
    </div>
    <!--规则-->
    <div class="rule-wrap" @click="ruleShow = true">
      <div class="rule-inner">
        <span>规则</span>
      </div>

    </div>
    <van-row type="flex" gutter="0" class="banner-list">
      <van-col span="24">
        <van-image v-for="(img, index) in activityInfo.bannerList"
                   width="100%"
                   lazy-load
                   :key="index"
                   :src="img"
        />
      </van-col>
      <van-col span="24">

        <div class="cell-group-wrap">
          <van-cell-group :border="false">
            <template #title>
              <div class="activity-title-wrap">
                <div class="activity-title-inner">
                  <img src="@/assets/images/actity-title-left.png" alt="">
                  <div class="activity-title">填写信息</div>
                  <img src="@/assets/images/actity-title-right.png" alt="">
                </div>
              </div>
            </template>
            <!--          <div class="cell-group-wrap">
                        <van-cell title="门店" center title-class="shop-title-class">
                          <div class="cell-content">
                            {{currentShop.name}}
                          </div>
                          <template #right-icon>
                            &lt;!&ndash;<div class="shop-icon-wrap">
                              <div class="shop-icon-inner">
                                <img src="@/assets/images/shop.png" alt="">
                              </div>
                            </div>&ndash;&gt;
                            <div class="shop-icon-inner">
                              <img src="@/assets/images/shop.png" alt="">
                            </div>

                          </template>
                        </van-cell>
                      </div>-->

            <van-cell title="门店" center title-class="shop-title-class">
              <div class="cell-content">
                {{ currentShop.name }}
              </div>
              <template #right-icon>
                <!--<div class="shop-icon-wrap">
                  <div class="shop-icon-inner">
                    <img src="@/assets/images/shop.png" alt="">
                  </div>
                </div>-->
                <div class="shop-icon-inner" @click="changeShopHandel">
                  <img src="@/assets/images/shop.png" alt="">
                </div>

              </template>
            </van-cell>

            <van-cell title="门店地址" center title-class="shop-title-class">
              <div class="cell-content">
                {{ currentShop.address }}
              </div>
              <template #right-icon>
                <!--<div class="shop-icon-wrap">
                  <div class="shop-icon-inner">
                    <img src="@/assets/images/shop.png" alt="">
                  </div>
                </div>-->
                <div class="shop-icon-inner" @click="openLocation">
                  <img src="@/assets/images/location.png" alt="">
                </div>

              </template>
            </van-cell>

            <van-cell title="服务电话" center title-class="shop-title-class">
              <div class="cell-content">
                {{ currentShop.phone }}
              </div>
              <template #right-icon>
                <!--<div class="shop-icon-wrap">
                  <div class="shop-icon-inner">
                    <img src="@/assets/images/shop.png" alt="">
                  </div>
                </div>-->
                <div class="shop-icon-inner" @click="makeCall">
                  <img src="@/assets/images/phone.png" alt="">
                </div>

              </template>
            </van-cell>
            <van-cell title="选择礼物" center title-class="shop-title-class" v-if="giftList&& giftList.length>0" is-link arrow-direction="down" @click="choseGiftHandle">
              <div class="cell-content">
                {{formData.giftName||'请选择礼物'}}
              </div>
            </van-cell>

            <van-cell title="车牌号" id="plate-line" ref="plate-line" center title-class="shop-title-class">
              <div class="cell-content" ref="plate">
                <plate @sheetOpened="actionSheetOpendedHandle" @closedHandle="plateClosedHandle"/>
              </div>
            </van-cell>
            <van-form ref="activityForm" :submit-on-enter="false" :validate-first="true">
              <van-cell title="手机号" center title-class="shop-title-class">
                <div class="cell-content">
                  <van-field
                      class="field-class"
                      v-model="formData.phone"
                      type="tel"
                      maxlength="11"
                      name="phone"
                      placeholder="请输入您的手机号"
                      :rules="[{ validator: validatorPhone, message: '手机号码有误' }]"
                  />
                </div>
              </van-cell>
              <van-cell title="验证码" center title-class="shop-title-class">
                <div class="cell-content">
                  <van-field
                      class="field-class"
                      v-model="formData.code"
                      type="text"
                      maxlength="6"
                      name="code"
                      placeholder="请输入验证码"
                      :rules="[{ validator: validatorCode, message: '验证码有误' }]"
                  />
                </div>
                <template #right-icon>
                  <div class="shop-icon-inner">
                    <van-button class="code-btn" plain :loading="codeLoading" :disabled="codeLessTime>0" size="small"
                                @click="codeGetHandel">
                      {{ codeLessTime > 0 ? '（' + codeLessTime + '）' : '获取' }}
                    </van-button>
                  </div>

                </template>
              </van-cell>
              <div class="space-30"></div>
            </van-form>
          </van-cell-group>
          <div class="buy-btn-big-wrap">
            <div class="buy-btn-big-inner">
              <div :class="{'buy-btn-big':true}" @click="submitOrderHandle">
                立即购买
              </div>
            </div>

          </div>

        </div>
      </van-col>
    </van-row>
    <van-row v-if="lastBuyers.length>0">
      <van-col span="24">
        <div class="cell-group-wrap mt-14">
          <!--          <van-cell-group :border="false">
                      <van-cell v-for="(item, index) in lastBuyers" :key="index" :border="false" title="单元格1"/>
                    </van-cell-group>-->
          <div class="last-buyer-wrap">
            <div class="inner">
              <vue-seamless-scroll :data="lastBuyers" class="scroll-wrap" :class-option="scrollOption">
              <div class="buyer-list" v-for="(item, index) in lastBuyers" :key="index">
                <!--                <van-icon :name="item.headimgurl" size="24" />-->
                <van-image
                    round
                    width="24"
                    height="24"
                    fit="cover"
                    :src="item.avatarUrl"
                />
                <span>{{ item.time }}，</span>
                <span>{{ item.licensePlateNumber }}&nbsp;购买成功</span>

              </div>
              </vue-seamless-scroll>
            </div>

          </div>
        </div>

      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <div class="cell-group-wrap buyer-list-part mt-10">
          <van-cell-group :border="false">
            <template #title>
              <div class="activity-title-wrap">
                <div class="buyer-list-float-wrap">
                  <div class="buyer-list-float-inner">
                    <div class="left">
                      <van-image :src="require('@/assets/images/buyer-user-l.png')" width="49" height="46"></van-image>
                    </div>
                    <div class="center">
                      <van-image :src="require('@/assets/images/buyer-user-c.png')" width="100%"
                                 height="46"></van-image>
                    </div>
                    <div class="right">
                      <van-image :src="require('@/assets/images/buyer-user-r.png')" width="49" height="46"></van-image>
                    </div>

                    <div class="float-text-wrap">
                      <div class="inner">
                        <div class="rect"></div>
                        <span>购买用户</span>
                        <div class="rect"></div>
                      </div>
                    </div>


                  </div>

                </div>
                <div class="activity-title-inner">

                </div>
              </div>
            </template>
            <van-cell :border="false">
              <div class="buyer-item">
                <div class="item">序号</div>
                <div class="item">车牌号</div>
                <div class="item">状态</div>
              </div>
            </van-cell>

            <van-cell :border="false" v-for="(item, index) in showBuyerList" :key="index">
              <div class="buyer-item">
                <div class="item">{{ item.id }}</div>
                <div class="item">{{ item.licensePlateNumber }}</div>
                <div class="item">购买成功</div>
              </div>
            </van-cell>
            <van-cell :border="false">
              <div class="buyer-more">
                <div class="more-action" @click="showBuyerMoreHandle">
                  <template  v-if="showBuyerMore">
                    查看更多&nbsp;&nbsp;<van-icon :name="require('@/assets/images/more-down-icon.png')" size="8"/>
                  </template>
                  <template v-else>
                    收起&nbsp;&nbsp;<van-icon :name="require('@/assets/images/more-up-icon.png')" size="8"/>
                  </template>
                </div>


              </div>
            </van-cell>


          </van-cell-group>

        </div>
      </van-col>

    </van-row>
    <van-row v-if="activityInfo.shopQr">
      <van-col span="24">

        <div class="cell-group-wrap">
          <van-cell-group :border="false">
            <template #title>
              <div class="activity-title-wrap">
                <div class="activity-title-inner">
                  <img src="@/assets/images/actity-title-left.png" alt="">
                  <div class="activity-title">扫码查看更多活动</div>
                  <img src="@/assets/images/actity-title-right.png" alt="">
                </div>
              </div>
            </template>
            <van-cell :border="false">
              <div class="share-qr-wrap">
                <van-image :src="activityInfo.shopQr" width="127" height="127"></van-image>
              </div>
            </van-cell>
          </van-cell-group>

        </div>
      </van-col>
    </van-row>
    <van-tabbar placeholder fixed safe-area-inset-bottom v-model="active">
      <!--      <van-tabbar-item>标签1</van-tabbar-item>
            <van-tabbar-item>标签2</van-tabbar-item>-->
      <van-row type="flex" align="bottom" style="width: 100%;">
        <van-col span="10">
          <van-button type="default" hairline block class="tabbar-btn" @click="makeCall">
            <div class="consult-inner">
              <img src="@/assets/images/consult.png" alt="咨询客服">咨询客服
            </div>

          </van-button>
        </van-col>
        <van-col span="14">
          <van-button class="tabbar-btn buy-btn" type="default" hairline block
                      color="linear-gradient(180deg, #FFEED6 13.64%, #F9D099 100%)" @click="submitOrderHandle">立即购买
          </van-button>
        </van-col>
      </van-row>
    </van-tabbar>

    <!--选择店铺-->
    <van-action-sheet v-model="choseShopSheetShow" cancel-text="取消">
      <div class="shop-select-wrap">
        <van-cell-group>
          <van-cell :title="shop.name" @click="shopSelectHandle(index)" v-for="(shop, index) in shopList" :key="index"
                    :icon="require('@/assets/images/shop-icon.png')">
            <template #right-icon v-if="currentShop.id === shop.id">
              <van-icon name="success" color="#E16D42" size="20"/>
            </template>
          </van-cell>
        </van-cell-group>
      </div>

    </van-action-sheet>
    <!--选择礼物-->
    <van-action-sheet v-model="choseGiftShow">
      <div class="chose-gift-wrap">
        <van-cell-group>
          <van-cell :title="item" @click="giftSelectHandle(item)" v-for="(item, index) in giftList" :key="index"
                    :icon="require('@/assets/images/gift-icon.png')">
            <template #right-icon v-if="formData.giftName === item">
              <van-icon name="success" color="#E16D42" size="20"/>
            </template>
          </van-cell>
        </van-cell-group>
      </div>

    </van-action-sheet>

    <!--分享*******输入手机号 -->
    <van-dialog v-model="shareStepPhoneShow"  ref="shareStepPhoneShow" :show-confirm-button="false">
      <template #title>
        <div class="dialog-share-title">
          <div class="title">
            填写手机号
          </div>
          <div class="close" @click="shareStepPhoneShow = false">
            <van-icon name="cross" />
          </div>

        </div>

      </template>
      <div class="dialog-wrap">
        <van-form ref="shareStepPhoneForm" :submit-on-enter="false" :validate-first="true">
          <van-field v-model="shareStepPhone.phone" type="tel"
                     style="background: rgba(247, 247, 247, 1);"
                     maxlength="11"
                     name="phone"
                     placeholder="请输入您的手机号"
                     :rules="[{ validator: validatorPhone, message: '手机号码有误' }]" />
          <div class="label-message">
            请输入您的常用手机号，生成专属链接，推荐邀请成功后可获取奖励
          </div>

        </van-form>

        <vbtn title="确认" :loading="shareStepPhoneLoading" :disable="!couldShare" @click="sendShareHandle"></vbtn>

      </div>


    </van-dialog>

    <!--    分享类型-->
    <van-dialog v-model="shareTypeShow"  ref="shareStepTypeShow" :show-confirm-button="false">
      <template #title>
        <div class="dialog-share-title">
          <div class="title">
            推广获得奖励
          </div>
          <div class="close" @click="shareTypeShow = false">
            <van-icon name="cross" />
          </div>

        </div>

      </template>
      <div class="dialog-wrap">
        <van-grid :column-num="2" :border="false" clickable>
          <van-grid-item text="生成海报" @click="shareToPictureHandle">
            <template #icon>
              <van-icon :name="require('@/assets/images/share-picture.png')" size="80"></van-icon>
            </template>
          </van-grid-item>
<!--          <van-grid-item text="分享给好友" @click="shareToFirendUserHandle">
            <template #icon>
              <van-icon :name="require('@/assets/images/share-firend-user.png')" size="56"></van-icon>
            </template>
          </van-grid-item>
          <van-grid-item text="分享到朋友圈" @click="shareToFirendLineHandle">
            <template #icon>
              <van-icon :name="require('@/assets/images/share-friend-line.png')" size="56"></van-icon>
            </template>
          </van-grid-item>-->
          <van-grid-item text="生成链接" @click="shareToLinkHandle">
            <template #icon>
              <van-icon :name="require('@/assets/images/share-link.png')" size="80"></van-icon>
            </template>
          </van-grid-item>

        </van-grid>

      </div>


    </van-dialog>

    <!--    支付成功-->
    <van-dialog v-model="paySuccessShow"  ref="paySuccessShow" :show-confirm-button="false">
      <template #title>
        <div class="dialog-success-title">
          <van-image :src="require('@/assets/images/success-bg.png')" width="100%"/>
          <div class="success-icon">
            <van-image :src="require('@/assets/images/success-icon.png')" width="58" height="58"/>
          </div>

        </div>



      </template>
      <div class="success-wrap">
        <div class="title">购买成功</div>
        <div class="desc">
          活动方会通过短信将本活动的详细指引发送至您手机上，请注意查收
        </div>
        <vbtn @click="successCloseHandle"></vbtn>
      </div>



    </van-dialog>

    <van-overlay :show="pageOverlayShow" class-name="page-overlay">
      <div class="page-overlay-wrapper">
        <div class="loading" >
<!--          <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>-->
          <van-loading type="spinner"  color="#FFFFFF" vertical>加载中...</van-loading>

        </div>
      </div>
    </van-overlay>
    <van-popup v-model="ruleShow" round position="bottom" close-on-popstate safe-area-inset-bottom>
      <rule/>
    </van-popup>

  </div>

</template>

<script>
import Vue from 'vue';
import {
  ActionSheet,
  Button,
  Cell,
  CellGroup,
  Col,
  Dialog,
  Divider,
  Field,
  Form,
  Grid,
  GridItem,
  Icon,
  Image as VanImage,
  Lazyload,
  Loading,
  Overlay,
  Popup,
  Row,
  Tabbar,
  TabbarItem,
  Toast
} from 'vant';

import plate from "../components/plate";
import Vbtn from "../components/Vbtn";
import Rule from "../components/Rule";
//import '../utils/jweixin-1.6.0'
import wx from 'weixin-js-sdk';

import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  activityDetail as fetchActivityDetail,
  getCode as fetchCode,
  payCheck,
  poster,
  share,
  shareDetail as fetchShareDetail,
  store,
  unifiedorder as fetchUnifiedorder
} from '../api/activity'

import {signatureConfig} from '../api/wechat'

import {getHrefUri, setPoster} from '../utils/helper'

Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Popup);

export default {
  name: "Home",
  components: {
    Vbtn,
    plate,
    vueSeamlessScroll,
    Rule,
    [Dialog.Component.name]: Dialog.Component,
  },
  created() {
    this.initPageData()
    this.initWechat()
  },
  data() {
    return {
      pageInfoReady:false,
      ruleShow: false,
      wxReady: false,
      pageOverlayShow: false,
      pageOverlayText: '加载中',
      title: '详情',
      requestPageName: '',
      activityId: null,
      shareId: null,
      giftList:[],
      scrollOption: {
        step: 1,
        limitMoveNum:3
      },
      active: 0,
      currentShowBuyerStep: 0,
      showBuyerStep: 5,
      maxShowBuyer: 30,
      choseShopSheetShow: false,
      choseGiftShow: false,
      // 宣传图列表
      activityInfo: {
        bannerList: [],
        shopQr: null
      },
      defaultCodeLessTime: 30,
      codeLessTime: 0,
      codeLoading: false,
      codeInterval: null,
      buyerList: [],
      shareUser: {
        id: null,
        name: "",
        avatarUrl: ''
      },
      formData: {
        plate: '',
        phone: '',
        code: '',
        giftName:''
      },
      shopList: [],
      currentShop: {
        id: null,
        name: '',
        address: '',
        phone: '',
        longitude: '',
        latitude: '',
      },
      //分享弹窗
      defaultShareStepPhone: {
        phone: ''
      },
      shareStepPhoneShow: false,
      shareStepPhoneLoading: false,
      currenePageShareId: null,
      shareStepPhone: {},
      shareTypeShow: false,
      paySuccessShow: false,
      lastBuyers:[],
      orderInfo: {
        id: null,
        name: null,
        summary: null,
        amountPayNeed: null,
      },

    }
  },
  computed: {
    couldBuy() {
      return this.formData.plate.length > 0 && this.validatorPhone(this.formData.phone) && this.validatorCode(this.formData.code)
    },
    couldShare() {
      return this.shareStepPhone.phone.length > 0 && this.validatorPhone(this.shareStepPhone.phone)
    },
    showBuyerList(){
      let endFlag = (this.currentShowBuyerStep + 1) * this.showBuyerStep
      endFlag = endFlag >= this.buyerList.length ? this.maxShowBuyer : endFlag
      return this.buyerList.slice(0, endFlag)||[]
    },
    showBuyerMore(){
      return this.showBuyerList.length < this.maxShowBuyer && this.showBuyerList.length  < this.buyerList.length
    }
  },
  methods: {
    // 分享页面初始化分享参数
    pageLoadShare(){
      try {
        if (this.wxReady && this.pageInfoReady) {
          const {name, params} = this.$route
          if (name === 'share-detail') {
            this.currenePageShareId = params.shareId
            this.updateAppMessageShareData()
            this.updateTimelineShareData()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    initWechat(){
      const href = window.location.href
      signatureConfig(href).then(res=>{
        if(res.code === '200'){
          const { appId, nonceStr, signature, timestamp } = res.data
          const weChatConfig = {
            //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature,// 必填，签名
            jsApiList: [
                'updateTimelineShareData',
                'updateAppMessageShareData',
                'onMenuShareAppMessage',  //旧的接口，即将废弃
                'onMenuShareTimeline', //旧的接口，即将废弃
                'chooseWXPay',
                'openLocation'
            ] // 必填，需要使用的JS接口列表
          }
          wx.config(weChatConfig)
          wx.ready(()=>{
            this.wxReady = true
          })
        }
      }, err=>{
        console.log(err)
      })

    },
    openLocation(){
      if (!this.wxReady) {
        return false
      }
      if (!this.currentShop.latitude || !this.currentShop.longitude) {
        return false
      }

      wx.openLocation({
        latitude: this.currentShop.latitude * 1, // 纬度，浮点数，范围为90 ~ -90
        longitude: this.currentShop.longitude * 1, // 经度，浮点数，范围为180 ~ -180。
        name: this.currentShop.name, // 位置名
        address: this.currentShop.address, // 地址详情说明
        scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      });

    },
    submitOrderHandle(){
      if(!this.couldBuy){
        Toast('请先填写信息！')
        /*Dialog.alert({
          message: '请先填写信息',
          theme: 'round-button',
        }).then(() => {
          // on close
        });*/
        return
      }
      const defaultOrderInfo = {
        id: null,
        name: null,
        summary: null,
        amountPayNeed: null,
      }
      this.orderInfo = Object.assign({}, defaultOrderInfo)
      const params = {
        activityId: undefined,
        shareId: undefined,
        phone: this.formData.phone,
        storeId: this.currentShop.id,
        licensePlateNumber: this.formData.plate,
        newEnergy: this.formData.plate.length === 7 ? 0 :1 ,
        code: this.formData.code,
        giftName: this.formData.giftName,
      }

      if(this.requestPageName === 'activity-detail'){
        params.activityId = this.activityId
      }else{
        params.shareId = this.shareId
      }

      this.pageOverlayShow = true

      store(params).then(res=>{
        // console.log(res)
        if(res.code === '200'){
          this.orderInfo.id = res.data.id
          this.orderInfo.name = res.data.name
          this.orderInfo.summary = res.data.summary
          this.orderInfo.amountPayNeed = res.data.amountPayNeed
          this.unifiedorder()
        }else{
          this.pageOverlayShow = false
          Toast.fail((res.message|| '购买失败'));
        }
      }, err=>{
        console.log(err)
        this.pageOverlayShow = false
        Toast.fail('购买出错了');
      })
    },
    unifiedorder(){
      fetchUnifiedorder(this.orderInfo.id).then(res=>{
        this.pageOverlayShow = false
        if(res.code === '200'){
          console.log(res)

          const {timestamp, nonceStr, signType, paySign} = res.data
          const params = {timestamp, nonceStr, package:res.data.package, signType, paySign}
          this.doPayOrder(params)
        }else{
          Toast.fail((res.message|| '购买失败'));
        }
      }, err=>{
        this.pageOverlayShow = false
        console.log(err)
        Toast.fail('支付出错了');
      })
    },
    doPayOrder(params){
      wx.chooseWXPay({
        timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
        package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: params.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: params.paySign, // 支付签名
        success: (res)=> {
          console.log(res)
          // 支付成功后的回调函数
          this.checkPayResult(this.orderInfo.id)
        },
        fail: function () {
          Toast.fail('支付失败');
        }
      })
    },
    checkPayResult(orderId){
      payCheck(orderId).then(res=>{
        if(res.code === '200'){
          Toast.success('购买成功');
        }else{
          Toast.fail((res.message|| '购买失败'));
        }
      }, error=>{
        console.log(error)
        Toast.fail('查询订单支付状态出错');
      })
    },
    initPageData(){
      this.shareStepPhone = Object.assign({}, this.defaultShareStepPhone)
      this.requestPageName = this.$route.name

      switch (this.requestPageName){
        case 'activity-detail':
          this.activityId = parseInt(this.$route.params.activityId)
          if(!this.activityId){
            this.pushTo404()
          }else{
            this.getActivityDetail()
          }


          break;
        case 'share-detail':
          this.shareId = parseInt(this.$route.params.shareId)
          if(!this.shareId){
            this.pushTo404()
          }else{
            this.getShareDetail()
          }
          break;
        default:
          this.pushTo404()
          break
      }
    },
    getActivityDetail(){
      fetchActivityDetail(this.activityId).then(res=>{
        if(res.code === '200'){
          this.initCommonDetail(res.data)
        }else{
          Toast.fail((res.message|| '取活动信息失败'));
        }
      }, err=>{
        console.log(err)
        Toast.fail('取活动信息出错');
      })
    },
    getShareDetail(){
      fetchShareDetail(this.shareId).then(res=>{
        if(res.code === '200'){
          this.initCommonDetail(res.data)
          this.initSharer(res.data.share)
        }else{
          Toast.fail((res.message|| '取活动信息失败'));
        }
      }, err=>{
        console.log(err)
        Toast.fail('取活动信息出错');
      })
    },
    initSharer(share){
      this.shareUser = Object.assign({}, {id:'', name:'', avatarUrl:''}, share)
    },
    initCurrentShop(){
      if(this.shopList && this.shopList.length > 0){
        const defaultShop = {
              id: null,
              name: '',
              address: '',
              phone: '',
              longitude:'',
              latitude:''
            }
        this.currentShop = Object.assign({}, defaultShop, this.shopList[0])
      }

    },
    initStoreList(storeList){
      this.shopList = storeList && storeList.length > 0 && storeList.map(item=>{
        const {id, name, address, phone, longitude, latitude} = item
        return {id, name, address, phone, longitude, latitude}
      })|| []
      this.initCurrentShop()
    },
    initShopQr(qrCodeUrl){
      qrCodeUrl && (this.activityInfo.shopQr = qrCodeUrl)
    },
    initPageTitle(title){
      title && (document.title = title)
    },
    initGiftList(giftList){
      this.giftList = giftList && giftList.length>0 ? giftList : []
      this.formData.giftName = giftList && giftList.length>0 ? giftList[0] : ''
    },
    initCarouselList(carouselList){
      this.activityInfo.bannerList = carouselList && carouselList.length > 0 && carouselList.map(item=>{return item.url}) || []
    },

    initRecentBuyList(recentBuyList){
      this.lastBuyers = recentBuyList && recentBuyList.length > 0 && recentBuyList.map(item=>{
        const {id, time, licensePlateNumber, avatarUrl} = item
        return {id, time, licensePlateNumber, avatarUrl}
      })|| []
    },

    initHistoryBoughtList(recentBuyList) {
      this.buyerList = recentBuyList && recentBuyList.length > 0 && recentBuyList.map(item => {
        const {id, licensePlateNumber} = item
        return {id, licensePlateNumber}
      }) || []
    },
    initCommonDetail(data){
      const {carouselList, name, qrCodeUrl, storeList, recentBuyList, historyBoughtList, giftList} = data
      this.title = name
      this.initStoreList(storeList)
      this.initShopQr(qrCodeUrl)
      this.initGiftList(giftList)
      this.initPageTitle(name)
      this.initCarouselList(carouselList)
      this.initRecentBuyList(recentBuyList)
      this.initHistoryBoughtList(historyBoughtList)
      this.pageInfoReady = true
    },
    pushTo404(){
      this.$router.push({name: '404'})
    },
    successCloseHandle(){
      this.paySuccessShow = false
    },
    // 分享链接
    shareToLinkHandle(){
      this.shareTypeShow = false
      if(this.shareId === this.currenePageShareId){
        return false
      }
      //this.$router.push({name: 'share-detail', params:{shareId: this.currenePageShareId}})
      window.location.href = getHrefUri('/activity-share-detail/'+this.currenePageShareId)
    },
    // 创建海报
    shareToPictureHandle(){
      const params = {
        activityId: undefined,
        shareId: undefined,
        phone: this.shareStepPhone.phone
      }
      if(this.requestPageName === 'activity-detail'){
        params.activityId = this.activityId
      }else{
        params.shareId = this.shareId
      }
      this.pageOverlayShow = true
      poster(params).then(res=>{
        this.pageOverlayShow = false
        if(res.code === '200'){
          setPoster(res.data.id, res.data.posterUrl)
          //this.$router.push({name: 'poster', params:{id: res.data.id}})
          window.location.href = getHrefUri('/poster/'+res.data.id)
        }else{
          Toast.fail((res.message|| '创建海报失败'));
        }

      }, err=>{
        this.pageOverlayShow = false
        console.log(err)
        Toast.fail('创建海报出错');

      })
    },
    getShareUrl(){
      const {protocol, host} = window.location
      const path = '/activity-share-detail/'+ this.currenePageShareId
      return protocol +'//'+ host + path
    },
    getShareImg(){
      return this.activityInfo.bannerList && this.activityInfo.bannerList.length>0 ? this.activityInfo.bannerList[0] : ''
    },
    shareToFirendUserHandle(){

    },
    shareToFirendLineHandle(){

    },
    updateAppMessageShareData(){
      console.log(this.getShareImg())
      wx.updateAppMessageShareData({
        title: this.title, // 分享标题
        desc: this.title, // 分享描述
        link: this.getShareUrl(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.getShareImg(), // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.onMenuShareTimeline({
        title: this.title, // 分享标题
        desc: this.title, // 分享描述
        link: this.getShareUrl(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.getShareImg(), // 分享图标
        success: function () {
          // 设置成功
        }
      })
    },
    updateTimelineShareData(){
      wx.updateTimelineShareData({
        title: this.title, // 分享标题
        link: this.getShareUrl(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.getShareImg(), // 分享图标
        success: function () {
          // 设置成功
        }
      })

      wx.onMenuShareAppMessage({
        title: this.title, // 分享标题
        link: this.getShareUrl(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.getShareImg(), // 分享图标
        success: function () {
          // 设置成功
        }
      })
    },
    doShare(){
      const params = {
        activityId: undefined,
        shareId: undefined,
        phone: this.shareStepPhone.phone
      }
      if(this.requestPageName === 'activity-detail'){
        params.activityId = this.activityId
      }else{
        params.shareId = this.shareId
      }
      this.shareStepPhoneLoading = true
      share(params).then(res=>{
        this.shareStepPhoneLoading = false
        if(res.code === '200'){
          this.currenePageShareId = res.data.id
          this.shareStepPhoneShow = false
          // 更新分享操作内容
          if(this.wxReady){
            this.updateAppMessageShareData()
            this.updateTimelineShareData()
          }

          // 弹出分享按钮
          this.shareTypeShow = true
        }else{
          Toast.fail((res.message|| '分享失败'));
        }

      }, err=>{
        this.shareStepPhoneLoading = false
        console.log(err)
        Toast.fail('分享出错了')
      })
    },

    // 输入手机号取分享
    sendShareHandle(){
      console.log('sendShareHandle')
      this.doShare()


    },
    shareBtnHandle() {
      this.shareStepPhoneShow = true
    },
    showBuyerMoreHandle(){
      this.currentShowBuyerStep = this.showBuyerMore ? this.currentShowBuyerStep + 1 : 0
    },
    shopSelectHandle(e) {
      console.log(e)
      this.currentShop = this.shopList[e]
      this.choseShopSheetShow = false
    },
    changeShopHandel() {
      this.choseShopSheetShow = true
    },
    choseGiftHandle(){
      this.choseGiftShow = true
    },
    giftSelectHandle(item){
      this.formData.giftName = item
      this.choseGiftShow = false
    },

    makeCall() {
      window.location.href = 'tel://' + this.currentShop.phone
    },
    validatorPhone(val) {
      return /1\d{10}/.test(val);
    },
    validatorCode(val) {
      return /^\d{6}$/g.test(val)
    },
    actionSheetOpendedHandle(plateSheetTop) {
      const scrollTop = document.getElementById('app').scrollTop
      const plateTop = this.$refs['plate-line'].getBoundingClientRect().top
      const plateHeight = this.$refs['plate-line'].getBoundingClientRect().height
      document.getElementById('app').scrollTop = scrollTop + (plateTop - plateSheetTop) + plateHeight + 15
    },
    plateClosedHandle(plateBytes) {
      let plateArr = []
      for (const plateKey in plateBytes) {
        // console.log(plateBytes[plateKey])
        const plateItem = plateBytes[plateKey]
        if (!plateItem && plateKey !== 7) {
          plateArr = []
          break
        }
        plateArr.push(plateItem)
      }
      this.formData.plate = plateArr.length > 0 ? plateArr.filter(item => {
        return !!item
      }).join('') : ''
    },

    codeGetHandel() {
      if (this.codeLoading || this.codeLessTime > 0) {
        return false
      }
      this.$refs.activityForm.resetValidation('code')
      this.$refs.activityForm.validate('phone').then(() => {
        this.getCode()
      }, error => {
        console.log(error)
      })

    },
    getCode() {
      this.codeLessTime = this.defaultCodeLessTime
      this.codeLoading = true
      fetchCode(this.formData.phone).then(res=>{
        this.codeLoading = false
        if(res.code === '200'){
          Toast.success('发送成功');
          this.codeInterval = setInterval(() => {
            this.codeLessTime--
            if (this.codeLessTime <= 0) {
              this.codeLessTime = 0
              clearInterval(this.codeInterval)
            }

          }, 1000)
        }else{
          Toast.fail((res.message|| '分享失败'));
        }

      }, error => {
        console.log(error)
        this.codeLoading = false
        Toast.fail('取验证码出错')
      })
    },

  },
  watch:{
    wxReady(val){
      val && this.pageLoadShare()
    },
    pageInfoReady(val){
      val && this.pageLoadShare()
    },
  }
}
</script>

<style scoped lang="sass">
$dialog-header-padding-top: 0px
$floatInnerHeight: 46px

.page-overlay
  background-color: rgba(0, 0, 0, 0.3)

.page-overlay-wrapper
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  .loading
    width: 120px
    height: 120px
    border-radius: 50%
    // background-color: #fff
//    background-color: rgba(255, 255, 255, 0.6)

.rule-wrap
  position: fixed
  top: 80px
  right: 0
  z-index: 102
  cursor: pointer
  .rule-inner
    height: 25px
    background: rgba(98, 14, 14, 0.5)
    border-top-left-radius: 15px
    border-bottom-left-radius: 15px
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    padding: 3px 15px
    color: #ffffff
    font-size: 14px
    &span
      margin-left: 10px

.share-action-wrap
  position: fixed
  bottom: 100px
  right: 0
  z-index: 101
  cursor: pointer

  .inner
    display: flex
    align-items: center
    padding: 20px 15px
    height: 20px
    background-color: #ffffff
    box-shadow: 0 0 10px rgba(116, 54, 7, 0.1)
    border-top-left-radius: 30px
    border-bottom-left-radius: 30px
    font-size: 13px
    line-height: 16px
    color: #666666

    .text-wrap
      display: flex
      flex-direction: column
      margin-left: 8px


.share-user-tag
  position: absolute
  top: 35px
  left: 0
  z-index: 100

  .wrap
    height: 25px
    background: rgba(98, 14, 14, 0.5)
    border-top-right-radius: 18px
    border-bottom-right-radius: 18px
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    padding: 3px 10px
    color: #ffffff
    font-size: 14px

    span
      margin-left: 10px


.banner-list
  font-size: 0
  line-height: 0
  position: relative

.tabbar-btn
  font-weight: 400
  font-size: 16px

.buy-btn
  color: #E16D42 !important
  border-color: transparent !important

.consult-inner
  height: 100%
  width: 100%
  display: flex
  flex-direction: row
  line-height: 16px

.consult-inner img
  height: 16px
  margin-right: 9px

.activity-title-wrap
  display: block
  width: 100%
  position: relative


.shop-icon-wrap
  /*height: 100%*/
  background-color: #06F
  display: block

.shop-icon-inner
  display: flex
  height: 100%
  justify-items: center
  align-items: center
  cursor: pointer


.shop-icon-inner img
  width: 15px
  height: auto
  margin-left: 15px


//.activity-title-wrap .ellipse-01
//  background: linear-gradient(251.84deg, #FFEEE4 23.75%, #FFDED4 73.39%)
//  transform: rotate(-4.75deg)
//  width: 105px
//  height: 105px
//  left: -42px
//  top: -72px
//  position: absolute



.activity-title-inner
  display: flex
  justify-content: center
  height: 55px
  width: 100%
  align-items: center
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  background: linear-gradient(180deg, #FFF4EC 0%, #FEE3D7 100%)

  img
    height: 15px

  .activity-title
    font-size: 18px
    color: #E16D42
    margin-left: 25px
    margin-right: 25px


.shop-title-class
  flex: 0
  min-width: 60px
  word-break: keep-all
  color: #666
  margin-right: 15px

.cell-content
  text-align: left
  color: #333333

.cell-group-wrap
  margin-left: 16px
  margin-right: 16px
  margin-top: 16px
  position: relative
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 152, 129, 0.2)
  border: 1px solid rgba(249, 116, 95, 0.3)
  border-radius: 10px

  .van-cell-group__title
    padding-left: 0
    padding-right: 0
    padding-top: 0

  .van-cell-group
    background-color: transparent

  .van-cell
    background-color: transparent

    .field-class
      padding: 0 0

  .buy-btn-big-wrap
    position: absolute
    width: 100%
    bottom: -24px

    .buy-btn-big-inner
      margin: 0 26px
      background-color: #f7f7f7

      display: flex
      flex: 1
      border-radius: 25px

      .buy-btn-big
        display: flex
        justify-content: center
        align-items: center
        height: 48px
        width: 100%
        cursor: pointer

        background: linear-gradient(180deg, #FF9881 13.64%, #EF4230 100%)
        box-shadow: 2px 2px 4px rgba(48, 48, 48, 0.2)
        border-radius: 25px
        font-size: 18px
        color: #ffffff
        text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.25)

        &.disable
          opacity: 0.5


.space-30
  width: 100%
  height: 30px

.chose-gift-wrap
  display: flex
  flex: 1
  flex-direction: column
  padding: 15px 15px

.shop-select-wrap
  display: flex
  flex: 1
  flex-direction: column
  padding: 15px 15px

.last-buyer-wrap
  display: flex
  flex-direction: row
  flex: 1
  justify-content: center

  .inner
    display: flex
    flex-direction: column
    justify-content: space-around
    margin-top: 8px
    margin-bottom: 8px
    .scroll-wrap
      width: 100%
      display: block
      height: 48px
      overflow: hidden

      .buyer-list
        font-size: 13px
        color: #999999
        display: flex
        flex-direction: row
        // justify-content: space-between
        align-items: center
        padding-top: 8px

        //&:first-child
        //  padding-top: 0

        .van-image
          margin-right: 15px

.buyer-list-float-wrap
  position: absolute
  padding: 0 30px
  width: calc(100% - 60px)
  top: -12px


  .buyer-list-float-inner
    position: relative
    display: flex
    justify-content: space-between
    width: 100%

    .left, .right
      height: $floatInnerHeight

    .center
      flex: 1
      height: $floatInnerHeight

    .float-text-wrap
      position: absolute
      height: $floatInnerHeight
      width: 100%
      left: 0
      top: 0
      // background-color: rgba(5, 0, 0, 0.07)
      .inner
        position: relative
        display: flex
        flex: 1
        height: $floatInnerHeight
        flex-direction: row
        justify-content: center
        align-items: center
        color: #984521
        font-size: 18px
        font-weight: 500

        span
          margin: auto 10px

        .rect
          background: #984521
          border-radius: 1px
          transform: rotate(45deg)
          width: 5px
          height: 5px


.buyer-list-part
  .van-cell
    padding: 0 0
    background-color: #FEFBF9

    &:nth-child(even)
      background-color: #FFF0EC

    &:last-child
      border-radius: 10px

    &:first-child
      .buyer-item
        .item
          height: 50px
          color: #f2733c
          font-size: 17px
    .buyer-more
      font-size: 14px
      height: 40px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      flex: 1
      color: #F2733C

      //border-bottom-left-radius: 10px
      //border-bottom-right-radius: 10px
      border-radius: 30px


      background-color: transparent

      .more-action
        cursor: pointer
        width: 100%
        text-align: center




  .buyer-item
    display: flex
    flex-direction: row
    justify-content: space-between
    flex: 1

    .item
      width: 33.33%
      display: flex
      justify-content: center
      align-items: center
      height: 40px
      color: #666666
      font-size: 15px

      &:last-child
        color: #54BAAE

.share-qr-wrap
  display: flex
  flex: 1
  justify-content: center
  align-items: center
  margin: 18px 0


.dialog-wrap
  padding: 0 15px
  margin-bottom: 20px
  .label-message
    color: #666666
    font-size: 15px
    padding: 15px 0






.dialog-share-title
  background: linear-gradient(180deg, #FFE3D9 8.33%, rgba(255, 227, 217, 0) 63.89%)
  color: rgba(51, 51, 51, 1)
  font-size: 17px
  width: 100%
  display: block
  position: relative
  .title
    display: flex
    justify-content: center
    align-items: center
    flex: 1
    height: 70px
  .close
    cursor: pointer
    position: absolute
    top: 20px
    right: 20px


.dialog-success-title
  display: block
  position: relative
  width: 100%
  .success-icon
    position: absolute
    text-align: center
    bottom: -15px
    left: calc(50% - 29px)

.success-wrap
  display: flex
  flex-direction: column
  margin: 0 25px
  .title
    margin-top: 20px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    font-size: 18px
    color: #333333
  .desc
    display: flex
    flex-direction: row
    justify-content: flex-start
    text-align: left
    font-size: 15px
    margin-top: 16px
    margin-bottom: 15px
    color: #666666


@for $i from 0 to 24
  .ma-#{$i}
    margin: $i * 3px

@for $i from 0 to 24
  .mx-#{$i}
    margin: 0 $i * 3px

@for $i from 0 to 24
  .my-#{$i}
    margin: $i * 3px, 0

@for $i from 0 to 24
  .ml-#{$i}
    margin-left: $i * 3px

@for $i from 0 to 24
  .mr-#{$i}
    margin-right: $i * 3px

@for $i from 0 to 24
  .mt-#{$i}
    margin-top: $i * 3px

@for $i from 0 to 24
  .mb-#{$i}
    margin-bottom: $i * 3px

@for $i from 0 to 24
  .pa-#{$i}
    padding: $i * 3px

@for $i from 0 to 24
  .px-#{$i}
    padding: 0 $i * 3px

@for $i from 0 to 24
  .py-#{$i}
    padding: $i * 3px 0

@for $i from 0 to 24
  .pl-#{$i}
    padding-left: $i * 3px

@for $i from 0 to 24
  .pr-#{$i}
    padding-right: $i * 3px

@for $i from 0 to 24
  .pt-#{$i}
    padding-top: $i * 3px

@for $i from 0 to 24
  .pb-#{$i}
    padding-bottom: $i * 3px
</style>

<style lang="sass">
.actifity-wrap
  .van-dialog__header
    padding-top: 0!important
</style>
